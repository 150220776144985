<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.776 2H3.33801C2.59801 2 1.99934 2.60267 2.00467 3.34333L2.07401 12.6767C2.07934 13.4093 2.67467 14 3.40734 14H12.6613C13.398 14 13.9947 13.4033 13.9947 12.6667V5.21867C13.9947 4.86533 13.854 4.526 13.604 4.276L11.7187 2.39067C11.4687 2.14067 11.13 2 10.776 2Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.662 2V4.606C10.662 4.974 10.3633 5.27267 9.99534 5.27267H5.99534C5.62734 5.27267 5.32867 4.974 5.32867 4.606V2"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.66667 14V8.85733C4.66667 8.384 5.05067 8 5.52401 8H10.4767C10.9493 8 11.3333 8.384 11.3333 8.85733V14"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "FloppyDiskIcon",
};
</script>
