<template>
  <div>
    <div v-if="!editing">
      <div class="rounded-lg bg-fonts-primary text-white px-3 py-1.5 flex items-center cursor-pointer" @click="toggleEdit()">
        <PencilIcon class="mr-1" />
        <span class="label-xs-500">Edit</span>
      </div>
    </div>
    <div v-else class="flex space-x-1.5">
      <div>
        <div class="rounded-lg bg-green-500 text-white px-3 py-1.5 flex items-center" :class="canSave ? 'cursor-pointer' : 'cursor-not-allowed'" @click="toggleEdit(true)">
          <FloppyDiskIcon class="mr-1" />
          <span class="label-xs-500">Save</span>
        </div>
      </div>
      <div class="rounded-lg bg-red-400 text-white px-3 py-1.5 flex cursor-pointer" @click="toggleEdit()">
        <XIcon stroke-width="2" class="w-4 h-4" />
      </div>
    </div>
  </div>
</template>

<script>
import PencilIcon from '@/components/shared/svg/PencilIcon';
import FloppyDiskIcon from '@/components/shared/svg/FloppyDiskIcon';
import XIcon from '@/components/shared/svg/XIcon';

export default {
  name: "EditButton",
  components: { PencilIcon, FloppyDiskIcon, XIcon },
  props: {
    canSave: { type: Boolean, default: false },
  },

  data() {
    return {
      editing: false,
    }
  },

  methods: {
    toggleEdit(save = false) {
      if (this.canSave || !save) {
        this.editing = !this.editing;
        this.$emit('toggle-edit', save);
      }
    },
  },
};
</script>
