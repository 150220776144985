<template>
  <div class="flex space-x-4">
    <!-- GO TO CONVERSATION WITH USER -->
    <div v-if="job.hiring_bid.thread_id" class="message" @click="goToConversation">
      <span>Message</span>
      <span v-if="unreadAmount > 0" class="unread-messages">
        {{ unreadAmount }}
      </span>
    </div>

    <!-- REPORT USER/BUSINESS -->
    <span class="button-label-m-500 cursor-pointer" @click="openReportUserModal">Report {{ reportUserText }}</span>
    
    <!-- ACTIVATE JOB -->
    <span v-if="!userIsPro && job.status == 'inactive' && job.type == 'recurring'" class="button-label-m-500 cursor-pointer" @click="openActivateJobModal">Activate job</span>

    <!-- PAUSE JOB -->
    <span v-if="!userIsPro && job.status == 'active' && job.type == 'recurring'" class="button-label-m-500 cursor-pointer" @click="openPauseJobModal">Pause job</span>
    
    <!-- END JOB -->
    <span class="button-label-m-500 cursor-pointer" @click="openEndJobModal"><span class="capitalize">{{closeJobText}}</span> job</span>
  </div>
</template>

<script>
import api from '@/api';
import { mapMutations, mapState } from "vuex";

export default {
  name: 'JobActions',

  data() {
    return {
      unreadAmount: 0,
    }
  },

  async mounted() {
    if (this.job.hiring_bid.thread_id) {
      const { data } = await api.get(`/messages/${this.job.hiring_bid.thread_id}/user-unread-count`);
      this.unreadAmount = data.data;
    }
  },

  methods: {
    ...mapMutations({
      setJob: "jobs/setJob",
      updateItem: "home/updateItem",
      showModal: 'general/showModal',
      hideModal: 'general/hideModal',
    }),

    goToConversation() {
      this.$router.push({ name: 'Inbox', params: { threadId: this.job.hiring_bid.thread_id } });
      this.hideModal();
    },

    openEndJobModal() {
      this.showModal({ componentName: 'EndJobModal' });
    },

    openPauseJobModal() {
      this.showModal({ componentName: 'PauseJobModal' });
    },

    openActivateJobModal() {
      this.showModal({ componentName: 'ActivateJobModal' });
    },

    openReportUserModal() {
      this.showModal({ componentName: 'ReportUserModal' });
    },
  },

  computed: {
    ...mapState({
      id: state => state.home.current.id,
      job: state => state.jobs.current,
      user: state => state.auth.user,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    closeJobText() {
      return this.userIsPro ? 'quit' : 'end';
    },

    reportUserText() {
      return this.userIsPro ? 'business' : 'user';
    },

    envIsProduction() {
      return process.env.NODE_ENV == 'production';
    }
  },
}
</script>

<style scoped>
.message {
  @apply button-label-m-500 cursor-pointer flex items-center space-x-1;
}

.unread-messages {
  @apply rounded-full h-5 w-5 bg-secondary-main text-white flex justify-center items-center body-xs-400;
}
</style>
