<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2317 5.90355L8.09668 3.76855"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.64642 12.2499H1.75V10.3534C1.75 10.1989 1.81125 10.0501 1.92092 9.94102L9.69908 2.16227C9.92717 1.93419 10.2964 1.93419 10.5239 2.16227L11.837 3.47536C12.0651 3.70344 12.0651 4.07269 11.837 4.30019L4.05883 12.0789C3.94975 12.1886 3.801 12.2499 3.64642 12.2499V12.2499Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "PencilIcon",
};
</script>

<style scoped></style>
