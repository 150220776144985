<template>
  <div v-if="job" class="w-full border-b px-6 lg:px-10 py-6 bg-white">
    <div class="flex items-center justify-between">
      <div class="flex items-center space-x-5">
        <AvatarImage :image="image" :isOnline="isOnline" width="w-10 lg:w-16" height="h-10 lg:h-16"/>
        <div class="flex flex-col">
          <h2 class="heading-s-700">{{userIsPro ? job.church.name : job.hiring_bid.pro.full_name}}</h2>
          <span class="body-s-400 text-gray-500">{{ userIsPro ? job.church.location : job.hiring_bid.pro.title }}</span>
        </div>
      </div>
      <JobActions />
    </div>

  </div>
</template>
<script>
import { mapState } from "vuex";
import AvatarImage from "@/components/shared/AvatarImage";
import JobActions from "@/components/modal-partials/job-modal/JobActions";

export default {
  name: 'Header',
  components: { AvatarImage, JobActions },

  computed: {
    ...mapState({
      job: state => state.jobs.current,
      user: state => state.auth.user,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    image() {
      return this.userIsPro ? this.job.church.logo_url : this.job.hiring_bid.pro.image_url
    },

    isOnline() {
      return this.userIsPro ? this.job.church.is_online : this.job.hiring_bid.pro.is_online
    },
  },
}
</script>
