var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex h-full bg-cream"},[_c('div',{staticClass:"m-auto w-full h-full"},[(_vm.loading)?_c('div',{staticClass:"flex flex-col justify-center items-center m-auto w-full h-full"},[_c('loading-icon',{staticClass:"h-2"})],1):(_vm.job)?_c('div',{staticClass:"flex flex-col justify-start items-center h-full"},[_c('Header'),_c('div',{staticClass:"modal-cont"},[_c('div',{staticClass:"flex flex-col col-span-5"},[_c('div',{staticClass:"flex justify-between"},[_c('h4',{staticClass:"mb-4 heading-s-600"},[_vm._v("Requirements")]),(_vm.canUpdateJob)?_c('EditButton',{attrs:{"canSave":_vm.canSaveRequirements},on:{"toggle-edit":_vm.toggleEdit}}):_vm._e()],1),_c('div',{staticClass:"overflow-auto",staticStyle:{"max-height":"400px"}},[(!_vm.editingRequirements)?_c('JobRequirements',{attrs:{"job":_vm.job,"canUpdateTasks":_vm.canUpdateTask,"loading":_vm.updatingJob}}):_c('div',[_c('TextAreaInput',{staticClass:"mb-8",attrs:{"label":"Update your job description","placeholder":"Add your details here...","rows":"5"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('ValidationProvider',{ref:"listInput",attrs:{"name":"Task","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tasks),expression:"form.tasks"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.form.tasks)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "tasks", $event.target.value)}}}),_c('ListInput',{ref:"listInput",attrs:{"label":"Add tasks one at a time and press enter","placeholder":"Type and press enter to add tasks","errors":errors,"initialValues":_vm.initialTasks},on:{"itemsUpdated":_vm.setTasks}})]}}])})],1)],1)]),_c('div',{staticClass:"w-full flex flex-col col-span-3"},[_c('div',{staticClass:"flex justify-between"},[_c('h4',{staticClass:"mb-4 heading-s-600"},[_vm._v("Details")]),(_vm.canUpdateJob)?_c('EditButton',{attrs:{"canSave":_vm.canSaveDetails},on:{"toggle-edit":function($event){return _vm.toggleEdit($event, false)}}}):_vm._e()],1),(!_vm.editingDetails)?_c('JobDetails',{attrs:{"job":_vm.job}}):_c('div',{staticClass:"flex flex-col space-y-3.5"},[_c('ValidationProvider',{attrs:{"name":"Job title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Job title","placeholder":"Graphic Designer","x-padding":"px-6","errors":errors},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Payment amount","x-padding":"px-6","placeholder":"100","currencyInput":true,"errors":errors},scopedSlots:_vm._u([{key:"leftIcon",fn:function(){return [_c('DollarIcon',{staticClass:"text-primary"})]},proxy:true}],null,true),model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})]}}])}),(_vm.job.type == 'one_time')?_c('ValidationProvider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"type":"date","label":"Completed by","x-padding":"px-6","placeholder":"05/30/2022","errors":errors},scopedSlots:_vm._u([{key:"leftIcon",fn:function(){return [_c('CalendarIcon',{staticClass:"text-primary mr-1.5"})]},proxy:true}],null,true),model:{value:(_vm.form.due_date),callback:function ($$v) {_vm.$set(_vm.form, "due_date", $$v)},expression:"form.due_date"}})]}}],null,false,2820339359)}):_vm._e()],1)],1)])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }