<template>
  <div class="flex h-full bg-cream">
    <div class="m-auto w-full h-full">
      <div v-if="loading" class="flex flex-col justify-center items-center m-auto w-full h-full">
        <loading-icon class="h-2"/>
      </div>
      <div v-else-if="job" class="flex flex-col justify-start items-center h-full">
        <!--Modal header-->
        <Header />
        <!--Modal body-->
        <div class="modal-cont">
          <div class="flex flex-col col-span-5">
            <div class="flex justify-between">
              <h4 class="mb-4 heading-s-600">Requirements</h4>
              <EditButton v-if="canUpdateJob" :canSave="canSaveRequirements" @toggle-edit="toggleEdit" />
            </div>
            <div style="max-height: 400px;" class="overflow-auto">
              <JobRequirements v-if="!editingRequirements" :job="job" :canUpdateTasks="canUpdateTask" :loading="updatingJob" />
              <div v-else>
                <TextAreaInput
                  v-model="form.description"
                  label="Update your job description"
                  placeholder="Add your details here..."
                  rows="5"
                  class="mb-8"
                />

                <ValidationProvider name="Task" rules="required" ref="listInput" v-slot="{ errors }">
                  <input type="hidden" v-model="form.tasks">
                  <ListInput
                    label="Add tasks one at a time and press enter"
                    ref="listInput"
                    placeholder="Type and press enter to add tasks"
                    @itemsUpdated="setTasks"
                    :errors="errors"
                    :initialValues="initialTasks"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="w-full flex flex-col col-span-3">
            <div class="flex justify-between">
              <h4 class="mb-4 heading-s-600">Details</h4>
              <EditButton v-if="canUpdateJob" :canSave="canSaveDetails" @toggle-edit="toggleEdit($event, false)" />
            </div>
            
            <JobDetails v-if="!editingDetails" :job="job" />
            <div v-else class="flex flex-col space-y-3.5">
              <ValidationProvider name="Job title" rules="required" v-slot="{ errors }">
                <TextInput
                  v-model="form.title"
                  label="Job title"
                  placeholder="Graphic Designer"
                  x-padding="px-6"
                  :errors="errors"
                />
              </ValidationProvider>

              <ValidationProvider name="Amount" rules="required" v-slot="{ errors }">
                <TextInput
                  v-model="form.amount"
                  label="Payment amount"
                  x-padding="px-6"
                  placeholder="100"
                  :currencyInput="true"
                  :errors="errors"
                >
                  <template #leftIcon>
                    <DollarIcon class="text-primary"/>
                  </template>
                </TextInput>
              </ValidationProvider>

              <ValidationProvider v-if="job.type == 'one_time'" name="Date" rules="required" v-slot="{ errors }">
                <TextInput
                  v-model="form.due_date"
                  type="date"
                  label="Completed by"
                  x-padding="px-6"
                  placeholder="05/30/2022"
                  :errors="errors"
                >
                  <template #leftIcon>
                    <CalendarIcon class="text-primary mr-1.5"/>
                  </template>
                </TextInput>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import {mapActions, mapMutations, mapState} from "vuex";
import Header from "@/components/modal-partials/job-modal/Header";
import EditButton from '@/components/modal-partials/job-modal/EditButton';
import TextAreaInput from "@/components/shared/inputs/TextAreaInput";
import ListInput from "@/components/shared/inputs/ListInput";
import TextInput from "@/components/shared/inputs/TextInput";
import DollarIcon from '@/components/shared/svg/DollarIcon';
import CalendarIcon from '@/components/shared/svg/CalendarIcon';
import JobRequirements from '@/components/jobs/JobRequirements';
import JobDetails from '@/components/jobs/JobDetails';

export default {
  name: 'JobModal',
  components: {
    Header,
    EditButton,
    TextAreaInput,
    ListInput,
    TextInput,
    DollarIcon,
    CalendarIcon,
    JobRequirements,
    JobDetails,
  },

  props: {
    props: Object,
  },

  data() {
    return {
      loading: false,
      updatingJob: false,
      editingRequirements: false,
      editingDetails: false,
      initialTasks: [],
      form: {
        title: '',
        description: '',
        tasks: [],
        amount: 0,
        due_date: '',
      },
    }
  },

  computed: {
    ...mapState({
      job: state => state.jobs.current,
      user: state => state.auth.user,
    }),

    userIsPro() {
      return this.user.userable_type == 'pro'
    },

    canUpdateJob() {
      return !this.userIsPro && this.job && this.job.status == 'active'
    },

    canUpdateTask() {
      return this.userIsPro && this.job && this.job.status == 'active'
    },

    canSaveRequirements() {
      return this.form.description != '' && this.form.tasks.length > 0;
    },

    canSaveDetails() {
      const mandatoryValidations = this.form.title != '' && this.form.amount != '';
      if (this.job.type == 'one_time') {
        return mandatoryValidations && this.due_date != '';
      }
      return mandatoryValidations;
    },
  },

  async mounted() {
    if (!this.job || this.job.id != this.props.jobId) {
      this.loading = true;
      await this.fetchJob({jobId: this.props.jobId, includes: 'church.hire_managers,hiring_bid,prorated_amount'})
      this.loading = false;
    }
      this.form.title = this.job.title;
      this.form.amount = this.job.job_amount;
      this.form.due_date =  moment.utc(this.job.due_date).format("yyyy-MM-DD");
      this.form.description = this.job.description;
      this.form.tasks = this.job.tasks;
      this.initialTasks = this.job.tasks.map(task => {
        return {id: task.id, text: task.name}
      });
  },

  methods: {
    ...mapActions({
      fetchJob: 'jobs/fetchJob',
      update: 'jobs/update',
    }),

    ...mapMutations({
      setJob: "jobs/setJob",
    }),

    async setTasks(tasks) {
      await this.$refs.listInput.validate();
      this.form.tasks = tasks.map(task => { 
        return { name: task.text, id: task.id };
      });
    },

    async toggleEdit(save, requirements = true) {
      const params = {};
      if (requirements) {
        this.editingRequirements = !this.editingRequirements;
        params['description'] = this.form.description;
        params['tasks'] = this.form.tasks;
      } else {
        this.editingDetails = !this.editingDetails;
        params['title'] = this.form.title;
        params['amount'] = this.form.amount;
        if (this.job.type == 'one_time') {
          params['due_date'] = this.form.due_date;
        }
      }
      if (save) {
        try {
          this.updatingJob = true;
          await this.update(params);
          await this.fetchJob({ jobId: this.job.id, includes: 'church.hire_managers,hiring_bid' });
          this.form.tasks = this.job.tasks;
          this.initialTasks = this.job.tasks.map(task => {
            return {id: task.id, text: task.name}
          });
          this.updatingJob = false;
        } catch (error) {
          if (error.data && error.data.message) {
            this.$toast.error(error.data.message);
          } else {
            this.$toast.error('There was an error. Try again later.');
          }
        } finally { 
          this.updatingJob = false;
        }
      }
    },
  },
}
</script>

<style scoped>
.modal-cont {
  @apply w-full h-full bg-cream rounded-b-2xl p-6 lg:px-7 lg:py-6 grid grid-cols-1 lg:grid-cols-8 gap-x-0 lg:gap-5 gap-y-5 lg:gap-y-0;
}
</style>